<template>
  <div
    style="position:relative"
  >
    <v-container
      fill-height
    >
      <v-row>
        <v-col
          cols="12"
        >
          <v-form>
            <v-text-field
              id="password"
              v-model="password"
              prepend-icon="lock"
              :rules="requireRules"
              :counter="10"
              autocomplete="off"
              name="password"
              label="변경비밀번호"
              clearable
            />
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <v-form>
            <v-text-field
              id="password-confirm"
              v-model="passwordConfirm"
              prepend-icon="lock"
              :rules="requireRules"
              autocomplete="off"
              :counter="10"
              name="password-confirm"
              label="변경비밀번호 확인"
              clearable
            />
          </v-form>
        </v-col>
      </v-row>
      <v-row
        class="mt-2"
      >
        <v-col
          cols="12"
        >
          <v-btn
            width="100%"
            color="primary"
            @click="onClickSave"
          >
            비밀번호 변경
          </v-btn>
        </v-col>
      </v-row>
      <loading
        :active.sync="isLoading"
        color="rgb(2, 110, 156)"
        loader="bars"
      />
    </v-container>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
// import AppLib from '../../share/AppLib'
// import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
// import HttpService from '../../share/service/HttpService'
// import AesCrypto from '../../share/service/AesCrypto'

export default {
  name: 'EmptView',
  components: {
    Loading
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      password: null,
      passwordConfirm: null,
      requireRules: [
        v => !!v || '항목을 입력하여 주세요',
        v => (v !== undefined && v !== null && v.length <= 16 && v.length >= 5) || '5~16자리 이내로 입력하여 주세요.'
      ]
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
  },
  mounted () {
    this.$Q('#password').focus()
  },
  updated () {
  },
  methods: {
    onClickSave (e) {
      if (this.password === null || this.passwordConfirm === null || this.password !== this.passwordConfirm) {
        this.$snotify.error('비밀번호를 확인하여 주세요')
        return
      } else if (this.password.length < 5 || this.passwordConfirm < 5) {
        this.$snotify.error('비밀번호는 5자리 이상 입력하여 주세요.')
        return
      } else if (this.password === this.userInfo.userId) {
        this.$snotify.error('비밀번호는 로그인 아이디(사번)과 다르게 변경하여 주세요.')
        return
      }
      this.isLoading = true
      const param = {
        사번: this.userInfo.userId,
        암호: this.password
      }
      this.$_sp.runUpdateSqlProc('직원정보', ['사번'], null, [param])
        .then((data) => {
          this.userInfo.passwd = this.password
          const option = this.$store.getters.getIsAutoLogin
          option.pa = this.password
          this.$store.commit('saveUserInfo', this.userInfo)
          this.$store.commit('saveIsAutoLogin', option)
          this.$snotify.info('변경 되었습니다.')
          this.$router.replace('/main')
        })
    }
  }
}
</script>

<style lang="scss">
</style>
