export default class UserInfoModel {
  constructor () {
    this.authResult = null
    this.userId = null
    this.passwd = null
    this.userName = null
    this.permission = null
    this.officeGroup = null
    this.userStatus = null
    this.division = null
    this.userImage = null
  }
}
