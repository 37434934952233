import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import 'vue-snotify/styles/material.css'
import jquery from 'jquery'
import underscore from 'vue-underscore'
import StoreProcPlugin from './share/service/plugin/StoreProcPlugin'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.dark.css'
import './assets/css/app.scss'
import 'vue-loading-overlay/dist/vue-loading.css'

Vue.use(StoreProcPlugin)
Vue.prototype.$Q = jquery
Vue.use(underscore)
const options = {
  toast: {
    position: SnotifyPosition.rightTop,
    timeout: 5000
  }
}
Vue.use(Snotify, options)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
