
import HttpService from './HttpService'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import ParamModel from '../domain/ParamModel'
import UserInfoModel from '../domain/UserInfoModel'
import JsEncrypt from 'jsencrypt/bin/jsencrypt'

export default class LoginByUserIdAndPasswdProc {
  static SendParamModel (userId, passwd, aesKey, aesIv, rsaKey, fnRsaEnc) {
    const jsonObject = {
      proc: 'spLoginByUserIdAndPasswd',
      ps: {
        aesKey: aesKey,
        userId: userId,
        passwd: passwd,
        aesIv: aesIv
      }
    }
    const plainText = JSON.stringify(jsonObject) // '{"version":"20180509.1","userId":"U00488","password":"eluonlbs","session":"nx49ea2hmv","forceCont":true,"receiver":true}'
    const encode = fnRsaEnc(plainText, rsaKey.publicKeyModulus, rsaKey.publicKeyExponent)
    const param = new ParamModel()
    param.command = ConstDef.CONNECT
    param.body = encode
    return HttpService.login(param)
  }

  static SendParamModelNoExponent (userId, passwd, aesKey, aesIv, rsaKey) {
    const encrypt = new JsEncrypt()
    encrypt.setPublicKey(rsaKey.publicKey)

    const jsonObject = {
      proc: 'spLoginByUserIdAndPasswd',
      ps: {
        aesKey: aesKey,
        userId: encrypt.encrypt(userId),
        passwd: encrypt.encrypt(passwd),
        aesIv: aesIv
      }
    }
    const plainText = JSON.stringify(jsonObject) // '{"version":"20180509.1","userId":"U00488","password":"eluonlbs","session":"nx49ea2hmv","forceCont":true,"receiver":true}'
    const param = new ParamModel()
    param.command = ConstDef.CONNECT
    param.body = plainText
    return HttpService.loginNoExponent(param)
  }

  static SendParamModelToken (token, aesKey, aesIv, rsaKey, fnRsaEnc) {
    const jsonObject = {
      proc: 'spFindCrmTokenByToken',
      ps: {
        aesKey: aesKey,
        token: token,
        aesIv: aesIv
      }
    }
    const plainText = JSON.stringify(jsonObject) // '{"version":"20180509.1","userId":"U00488","password":"eluonlbs","session":"nx49ea2hmv","forceCont":true,"receiver":true}'
    const encode = fnRsaEnc(plainText, rsaKey.publicKeyModulus, rsaKey.publicKeyExponent)
    const param = new ParamModel()
    param.command = ConstDef.CONNECT
    param.body = encode
    return HttpService.login(param)
  }

  static MakeModel (data) {
    const json = JSON.parse(data.body)
    const userInfo = new UserInfoModel()
    userInfo.authResult = json.authResult
    userInfo.passwd = json.passwd
    userInfo.userId = json.userId
    userInfo.userName = json.userName
    userInfo.permission = json.permission
    userInfo.userImage = json.userImage
    userInfo.division = json.division
    return userInfo
  }
}
