import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    aesKey: null,
    aesIv: null,
    companyInfo: null,
    userInfo: null,
    topMenu: null,
    baseData: null,
    isAutoLogin: null,
    tempArg: null,
    temp: null,
    sysParam: null,
    view: null,
    model: null,
    productList: null,
    carNo: null
  },
  getters: {
    getProductInfoSaveTime (state) {
      const productInfoSaveTime = localStorage.getItem('productInfoLastUpdateTime')
      if (productInfoSaveTime === undefined || productInfoSaveTime === null) {
        return 0
      } else {
        return Number(productInfoSaveTime)
      }
    },
    getAesKey: function (state) {
      if (state.aesKey === null) {
        state.aesKey = sessionStorage.getItem('aesKey')
      }
      return state.aesKey
    },
    getAesIv: function (state) {
      if (state.aesIv === null) {
        state.aesIvSingle = sessionStorage.getItem('aesIv')
      }
      return state.aesIv
    },
    getUserInfo: function (state) {
      if (state.userInfo === null) {
        state.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      }
      return state.userInfo
    },
    getTopMenu (state) {
      if (state.topMenu === null) {
        state.topMenu = JSON.parse(sessionStorage.getItem('topMenu'))
      }
      return state.topMenu
    },
    getBaseData (state) {
      if (state.baseData === null) {
        state.baseData = JSON.parse(sessionStorage.getItem('baseData'))
      }
      return state.baseData
    },
    getBaseDataByType: (state) => (id) => {
      const baseDataArray = []
      if (state.baseData === null) {
        state.baseData = JSON.parse(sessionStorage.getItem('baseData'))
      }
      if (state.baseData !== null) {
        for (let i = 0; i < state.baseData.length; i++) {
          if (state.baseData[i].상위코드 === id) {
            baseDataArray.push(state.baseData[i])
          }
        }
      }
      return baseDataArray
    },
    getGroupName (state) {
      if (state.sysParam === null) {
        state.sysParam = JSON.parse(sessionStorage.getItem('sysParam'))
      }
      const result = state.sysParam.find(item => item.key === '생산그룹명')
      if (result === undefined || result === null) return null
      return result.value
    },
    getSysParam (state) {
      if (state.sysParam === null) {
        state.sysParam = JSON.parse(sessionStorage.getItem('sysParam'))
      }
      return state.sysParam
    },
    getSysParamByKey: (state) => (key) => {
      if (state.sysParam === null) {
        state.sysParam = JSON.parse(sessionStorage.getItem('sysParam'))
      }
      const result = state.sysParam.find(item => item.key === key)
      if (result === undefined || result === null) return null
      return result.value
    },
    getTempArgData (state) {
      return state.tempArg
    },
    getTempData (state) {
      return state.temp
    },
    getIsAutoLogin (state) {
      state.isAutoLogin = JSON.parse(localStorage.getItem('isAutoLogin'))
      if (state.isAutoLogin === undefined || state === null) {
        return null
      }
      return state.isAutoLogin
    },
    getViewWidth (state) {
      return state.view
    },
    getMobileModel (state) {
      if (state.model === null) {
        state.model = sessionStorage.getItem('mobileModel')
      }
      return state.model
    },
    getPurchaseList: (state) => (id) => { // id : 자재유형
      if (state.productList === null) {
        state.productList = JSON.parse(localStorage.getItem('productList'))
      }
      return state.productList.filter(item => item.자재유형 === id)
    },
    getGoodsItemByGoodsNo: (state) => (id) => {
      if (state.productList === null) {
        state.productList = JSON.parse(localStorage.getItem('productList'))
      }
      return state.productList.find(item => item.품번 === id)
    },
    getGoodsList: (state) => (id) => { // id: 품목유형
      if (state.productList === null) {
        state.productList = JSON.parse(localStorage.getItem('productList'))
      }
      let result = null
      if (id === 'ALL') {
        return state.productList
      } else {
        result = state.productList.filter(item => item.품목유형 === id)
      }
      return result
    },
    getPartnersInfo (state) {
      if (state.companyInfo === null) {
        state.companyInfo = JSON.parse(sessionStorage.getItem('companyInfo'))
      }
      return state.companyInfo.filter(item => item.거래처구분코드 === '협력사')
    },
    getCustomerInfo (state) {
      if (state.companyInfo === null) {
        state.companyInfo = JSON.parse(sessionStorage.getItem('companyInfo'))
      }
      return state.companyInfo.filter(item => item.거래처구분코드 === '발주사')
    },
    getCompanyInfo (state) {
      if (state.companyInfo === null) {
        state.companyInfo = JSON.parse(sessionStorage.getItem('companyInfo'))
      }
      return state.companyInfo
    }
  },
  mutations: {
    saveProductInfoSaveTime (state, value) {
      console.log(value)
      localStorage.setItem('productInfoLastUpdateTime', `${value}`)
    },
    deleteProductInfoSaveTime (state) {
      localStorage.removeItem('productInfoLastUpdateTime')
    },
    saveAesKeyIv (state, { key, iv }) {
      state.aesKey = key
      // // console.log('disk=' + key)
      state.aesIv = iv
      sessionStorage.setItem('aesKey', key)
      sessionStorage.setItem('aesIv', iv)
    },
    saveAesKey (state, key) {
      state.aesKey = key
      sessionStorage.setItem('aesKey', key)
    },
    saveAesIv (state, iv) {
      state.aesIv = iv
      sessionStorage.setItem('aesIv', iv)
    },
    saveIsAutoLogin (state, option) {
      state.isAutoLogin = option
      localStorage.setItem('isAutoLogin', JSON.stringify(option))
    },
    deleteIsLogin (state) {
      localStorage.removeItem('isAutoLogin')
    },
    saveUserInfo (state, userInfo) {
      state.userInfo = userInfo
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
      console.log(userInfo)
    },
    saveSysParam (state, param) {
      state.sysParam = param
      sessionStorage.setItem('sysParam', JSON.stringify(param))
    },
    deleteUserInfo (state) {
      state.userInfo = null
      sessionStorage.removeItem('userInfo')
    },
    deleteAesKeyIv (state) {
      state.aesKey = null
      state.aesIv = null
      sessionStorage.removeItem('aesKey')
      sessionStorage.removeItem('aesIv')
    },
    deleteBaseData (state) {
      state.baseData = null
      sessionStorage.removeItem('baseData')
    },
    saveCompany (state, param) {
      state.companyInfo = param
      sessionStorage.setItem('companyInfo', JSON.stringify(param))
    },
    saveTopmenu (state, topMenu) {
      state.topMenu = topMenu
      sessionStorage.setItem('topMenu', JSON.stringify(topMenu))
    },
    saveBaseData (state, baseData) {
      state.baseData = baseData
      sessionStorage.setItem('baseData', JSON.stringify(baseData))
    },
    saveProductList (state, list) {
      localStorage.setItem('productList', JSON.stringify(list))
      state.productList = list
    },
    saveTempArgData (state, arg) {
      state.tempArg = arg
    },
    deleteTempArgData (state) {
      state.tempArg = null
    },
    saveTempData (state, arg) {
      state.temp = arg
    },
    deleteTempData (state) {
      state.temp = null
    },
    saveViewWidth (state, width) {
      state.view = width
    },
    saveMobileModel (state, model) {
      state.model = model
      sessionStorage.setItem('mobileModel', model)
    }
  },
  actions: {

  }
})
