<template>
  <v-app id="inspire">
    <v-main>
      <v-container
        fluid
        fill-height
        class="main-wrap"
      >
        <div
          v-if="$store.getters.getMobileModel !== 'PDA' && showQrScaner==='block'"
          class="qrcode-wrap"
        >
          <QrCodeScanComponent @scan-result="onPCodeScan" />
        </div>
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs12
            sm8
            md4
            style="margin-top:-30px;"
          >
            <v-card
              v-if="!isQrCode"
              class="elevation-12"
            >
              <v-toolbar
                dark
                color="#546E7A"
              >
                <v-toolbar-title>{{ loginTitle }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pa-2">
                <v-form>
                  <v-text-field
                    id="login-id-input"
                    v-model="loginId"
                    autocomplete="off"
                    prepend-icon="person"
                    name="login"
                    label="Login ID"
                    type="text"
                    :rules="requireRules"
                    :counter="16"
                    clearable
                  />
                  <v-text-field
                    id="password"
                    v-model="passwd"
                    autocomplete="off"
                    prepend-icon="lock"
                    :rules="requireRules"
                    :counter="10"
                    name="password"
                    label="Password"
                    type="password"
                    clearable
                  />
                  <v-checkbox
                    v-model="isAutoLogin"
                    class="form-no-messages mt-1"
                    label="자동로그인"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="success"
                  @click="checkQrLogin"
                >
                  QR모드
                </v-btn>
                <v-spacer />
                <v-btn
                  color="primary"
                  @click="checkLogin"
                >
                  로그인
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-card
              v-if="isQrCode"
              class="elevation-12"
            >
              <v-toolbar
                dark
                color="primary"
              >
                <v-toolbar-title>{{ loginTitle }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pa-2">
                <v-form>
                  <v-text-field
                    id="login-id-input"
                    v-model="qrLoginId"
                    prepend-icon="mdi-camera"
                    autocomplete="off"
                    name="login"
                    label="QR Code 스캔"
                    clearable
                    type="password"
                    @keyup.enter.prevent="onEnterPressed"
                  />
                  <v-text-field
                    id="password"
                    style="display:none"
                    autocomplete="off"
                    prepend-icon="lock"
                    name="password"
                    label="Password"
                    type="password"
                  />
                  <v-checkbox
                    v-model="isAutoLogin"
                    class="form-no-messages mt-1"
                    label="자동로그인"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  @click="checkQrLogin"
                >
                  일반로그인 모드
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <loading :active.sync="isLoading" />
  </v-app>
</template>

<script>

import HttpService from '../share/service/HttpService'
import ConstDef from '../../../MesSmartVue/src/share/ConstDef'
import AppLib from '../share/AppLib'
import Loading from 'vue-loading-overlay'
import LoginByUserIdAndPasswdProc from '../share/service/LoginByUserIdAndPasswdProc'
import QrCodeScanComponent from '../components/QrCodeScanComponent'

export default {
  name: 'Login',
  components: {
    Loading,
    QrCodeScanComponent
  },
  data: function () {
    return {
      isLoading: false,
      isQrCode: false,
      isAutoLogin: true,
      loginTitle: '일반 로그인',
      loginId: null,
      qrLoginId: null,
      passwd: null,
      requireRules: [
        v => !!v || '항목을 입력하여 주세요',
        v => (v !== undefined && v !== null && v.length <= 16 && v.length >= 5) || '5~16자리 이내로 입력하여 주세요.'
      ],
      showQrScaner: 'none',
      isLoggingProcess: false
    }
  },
  created () {
    if (navigator.userAgent.indexOf('M3SM') >= 0) {
      this.$store.commit('saveMobileModel', 'PDA')
    } else if (AppLib.isTabletDevice() === true) {
      this.$store.commit('saveMobileModel', 'TABLET')
    } else {
      this.$store.commit('saveMobileModel', 'MOBILE')
    }
    if (this.$route.path.indexOf('logout') >= 0) {
      this.$store.commit('deleteUserInfo')
      this.$store.commit('deleteAesKeyIv')
      this.$store.commit('deleteBaseData')
      this.$store.commit('deleteIsLogin')
      HttpService.logout()
        .then((data) => {
        })
        .catch(error => {
          this.$snotify.error(`에러코드 : ${error.response.status}`)
        })
    }
    if (this.$store.getters.getAesKey === null || this.$store.getters.getAesIv === null) {
      this.createAesKey()
    }
    if (this.isQrCode) {
      this.loginTitle = 'QR 로그인'
    }
    const option = this.$store.getters.getIsAutoLogin
    console.log(option)
    if (option !== null) {
      if (option.isAutoLogin && this.$store.getters.getMobileModel !== 'PDA') {
        this.loginId = option.id
        this.login(option.id, option.pa)
      }
    }
  },
  beforeMount () {
  },
  mounted () {
    if (this.$store.getters.getMobileModel !== 'PDA') {
      const vm = this
      this.$Q('.v-input__icon--prepend').click(function () {
        console.log('*************')
        vm.showQrScaner = vm.showQrScaner === 'none' ? 'block' : 'none'
      })
    } else {
      this.isQrCode = true
    }
    this.$Q('#login-id-input').focus()
  },
  updated () {
    // this.$Q('#login-id-input').focus()
  },
  methods: {
    checkLogin (e) {
      let split = null
      if (this.isQrCode) {
        if (this.qrLoginId === null || this.qrLoginId.length === 0) {
          this.$snotify.error('잘못된 사용자 정보입니다.')
          return
        }
        split = this.qrLoginId.split('/')
        if (split.length < 2) {
          this.$snotify.error('잘못된 사용자 정보입니다.')
          return
        }
        this.login(split[0], split[1])
      } else {
        if (this.loginId === null || this.loginId.length === 0 || this.passwd === null || this.passwd.length === 0) {
          this.$snotify.error('잘못된 사용자 정보입니다.')
          return
        }
        this.login(this.loginId, this.passwd)
      }
    },
    checkQrLogin (e) {
      this.loginId = this.qrLoginId = this.passwd = null
      this.isQrCode = !this.isQrCode
      if (this.isQrCode) {
        this.loginTitle = 'QR 로그인'
      } else {
        this.loginTitle = '일반 로그인'
        this.showQrScaner = 'none'
      }
    },
    async login (userId, userPa) {
      let rsaKey
      this.isLoading = true
      this.isLoggingProcess = true
      await HttpService.connectNoExponent()
        .then((data) => {
          rsaKey = data
        })
        .catch(error => {
          this.isLoading = false
          this.isLoggingProcess = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
      await LoginByUserIdAndPasswdProc.SendParamModelNoExponent(userId, userPa, this.$store.getters.getAesKey, this.$store.getters.getAesIv, rsaKey)
        .then((data) => {
          this.isLoading = false
          if (data.result === ConstDef.SUCCESS) {
            const userInfo = LoginByUserIdAndPasswdProc.MakeModel(data)

            if (userInfo.authResult === ConstDef.SUCCESS) {
              if (userInfo.userStatus === 'T') {
                this.$snotify.error('해지된 사용자 입니다.')
              } else if (userInfo.userStatus === 'P') {
                this.$snotify.error('일시중지된 사용자 입니다.')
              } else {
                this.$store.commit('saveUserInfo', userInfo)
                this.$store.commit('saveIsAutoLogin', { isAutoLogin: this.isAutoLogin, id: userInfo.userId, pa: userInfo.passwd })
                this.loginLogApi(userInfo, data.loginIp)
                if (userId === userPa) {
                  this.$router.push('/main/mypage')
                } else {
                  this.$router.replace('/main').catch(() => {})
                }
              }
            } else {
              this.$snotify.error('로그인 정보가 정확하지 않습니다.')
              this.isLoggingProcess = false
            }
          } else {
            // console.log(data.result)
            this.isLoggingProcess = false
          }
        })
        .catch(error => {
          this.isLoading = false
          this.isLoggingProcess = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    loginLogApi (userInfo, ip) {
      var param = {
        crtfcKey: '$5$API$tczlHER8NCjmxPosi.B90Z3F1c2qlELamQA0mMm3xX4',
        logDt: AppLib.getNowMilliseconds(),
        useSe: '접속',
        sysUser: `${userInfo.userId}`,
        conectIp: ip,
        dataUsgqty: 0
      }
      this.$Q.ajax({
        type: 'POST',
        url: 'https://log.smart-factory.kr/apisvc/sendLogData.json',
        cache: false,
        timeout: 360000,
        data: param,
        dataType: 'json',
        contentType: 'application/x-www-form-urlencoded; charset=utf-8',
        beforeSend: function () {
        },
        success: function (data, textStatus, jqXHR) {
          var result = data.result
          console.log(result) // <-- 전송 결과 확인
        },
        error: function (jqXHR, textStatus, errorThrown) {
        },
        complete: function () {
        }
      })
    },
    createAesKey () {
      const v = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      const len = v.length
      let session = ''
      for (let i = 0; i < 32; i++) {
        const ran = Math.floor(Math.random() * len)
        session += v.substr(ran, 1)
      }
      this.$store.commit('saveAesKey', session)
      session = ''
      for (let i = 0; i < 16; i++) {
        const ran = Math.floor(Math.random() * len)
        session += v.substr(ran, 1)
      }
      this.$store.commit('saveAesIv', session)
    },
    onEnterPressed (e) {
      this.qrLoginId = AppLib.base64DecodeSubstring(this.qrLoginId)
      if (this.isLoggingProcess === false) {
        this.checkLogin(null)
      }
    },
    onPCodeScan (result) {
      this.qrLoginId = result
      this.onEnterPressed(null)
    }
  }
}

</script>

<style lang="scss">
.main-wrap {
  background-image: url(../assets/images/main_top.png),
    url(../assets/images/login_bottom.png);
  background-repeat: no-repeat,no-repeat;
  background-position: top left, bottom right;
  background-size: 120px, 200px;
  position: relative;
}
</style>
