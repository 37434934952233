<template>
  <div>
    <div class="camera-wrap">
      <qrcode-stream
        :camera="camera"
        @decode="onDecode"
        @init="onInit"
      >
        <div
          v-show="showScanConfirmation"
          class="scan-confirmation"
        >
          <img
            src="/images/checkmark.svg"
            alt="Checkmark"
            width="90px"
          >
        </div>
      </qrcode-stream>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import AesCrypto from '../share/service/AesCrypto'
import ConstDef from '../../../MesSmartVue/src/share/ConstDef'

export default {
  name: 'QrReader',
  components: {
    QrcodeStream
  },
  data: function () {
    return {
      error: 'no error',
      camera: 'auto',
      showScanConfirmation: false
    }
  },
  methods: {
    async onInit (promise) {
      try {
        await promise
      } catch (e) {
        console.error(e)
      } finally {
        this.showScanConfirmation = this.camera === 'off'
      }
    },
    async onDecode (content) {
      let decode = null
      if (ConstDef.ENCODE_BASE64) {
        // decode = AesCrypto.base64Decode(content.substring(0, content.indexOf('HONGIK')))
        decode = AesCrypto.base64Decode(content)
      } else {
        // decode = content.substring(0, content.indexOf('HONGIK'))
        decode = content.substring(0)
        console.log(decode)
      }
      console.log(decode)
      decode = decode.replace('?', ' ')
      console.log(decode)
      this.$emit('scan-result', decode)
      this.pause()
      await this.timeout(1000)
      this.unpause()
    },
    unpause () {
      this.camera = 'auto'
    },

    pause () {
      this.camera = 'off'
    },
    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    }
  }
}
</script>

<style>
.camera-wrap {
  height: 250px;
  max-width: 350px;
  margin: 0 auto;
}
.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
</style>
