import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        appbar: '#546E7A',
        primary: '#206094',
        error: '#F44336',
        accent: '#4DD0E1',
        success: '#00C853',
        warning: '#FFEA00',
        danger: '#FF0000'
      }
    }
  }
})
