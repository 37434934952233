import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Main from '../views/Main.vue'
import MyPage from '../views/MyPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Login
  },
  {
    path: '/main',
    name: 'MES-POP',
    component: Main,
    children: [
      {
        path: 'regProduct',
        name: '완제품 실적 등록',
        component: () => import('../views/Manufacturing/RegProductPallet.vue')
      },
      {
        path: 'regSemiProduct',
        name: '반제품 실적 등록',
        component: () => import('../views/Manufacturing/RegSemiProductPallet.vue')
      },
      {
        path: 'purchaseDelivery',
        name: '발주 자재입고',
        component: () => import('../views/PurchaseReceipt/PurchaseDelivery.vue')
      },
      {
        path: 'purchaseNoDeliveryS0409',
        name: '외주직납품 입고',
        component: () => import('../views/PurchaseReceipt/PurchaseNoDeliveryS0409.vue')
      },
      {
        path: 'purchaseNoDeliveryS0402',
        name: '사급 원자재 입고',
        component: () => import('../views/PurchaseReceipt/사급원자재_입고_뷰.vue')
      },
      {
        path: '원자재시중구매',
        name: '원자재 시중구매',
        component: () => import('../views/PurchaseReceipt/원자재_시중구매_뷰.vue')
      },
      {
        path: 'purchaseNoDeliveryOthers',
        name: '외주품입고 현황',
        component: () => import('../views/PurchaseReceipt/PurchaseNoDeliveryOthers.vue')
      },
      {
        path: '원자재용도변경출고',
        name: '원자재 용도변경 출고',
        component: () => import('../views/PurchaseReceipt/원자재_용도변경_출고_뷰.vue')
      },
      {
        path: '원자재용도변경입고',
        name: '원자재 용도변경 입고',
        component: () => import('../views/PurchaseReceipt/원자재_용도변경_입고_뷰.vue')
      },
      {
        path: 'locationMove',
        name: '자재위치이동',
        component: () => import('../views/PurchaseReceipt/LocationMove.vue')
      },
      {
        path: 'purchaseTakeout',
        name: '자재출고',
        component: () => import('../views/PurchaseReceipt/StorageTakeoutTab.vue')
      },
      {
        path: 'wipTakeout',
        name: '다공정원자재출고',
        component: () => import('../views/PurchaseReceipt/WipMaterialTakeoutTab.vue')
      },
      {
        path: 'badPurchaseTakeout',
        name: '불량자재출고(반품)',
        component: () => import('../views/PurchaseReceipt/StorageBadTakeout.vue')
      },
      {
        path: 'productionReceipt',
        name: '제품 출하',
        component: () => import('../views/ProductionReceipt/ProductionReceipt.vue')
      },
      {
        path: 'purchaseS0409Receipt',
        name: '외주직납품 출하',
        component: () => import('../views/ProductionReceipt/PurchaseGoods.vue')
      },
      {
        path: 'purchaseReceiptNoScan',
        name: '기타품목 출하',
        component: () => import('../views/ProductionReceipt/PurchaseReceipt.vue')
      },
      {
        path: 'productionReceiptList',
        name: '일별 출하내역',
        component: () => import('../views/ProductionReceipt/DallyProductionReceipt.vue')
      },
      {
        path: 'productionReturn',
        name: '출하 반품',
        component: () => import('../views/ProductionReceipt/ProductionReturn.vue')
      },
      {
        path: 'productionReturnList',
        name: '출하 반품 내역',
        component: () => import('../views/ProductionReceipt/ProductionReturnList.vue')
      },
      {
        path: 'purchaseStock',
        name: '구매품 현재고',
        component: () => import('../views/ProductionReceipt/CurrentStockTabS0106.vue')
      },
      {
        path: 'productStock',
        name: '완제품 현재고',
        component: () => import('../views/ProductionReceipt/CurrentStockTabS0100.vue')
      },
      {
        path: 'semiProductStock',
        name: '용접반제품 현재고',
        component: () => import('../views/ProductionReceipt/CurrentStockTabS0102.vue')
      },
      {
        path: 'pressProductStock',
        name: '프레스제품 현재고',
        component: () => import('../views/ProductionReceipt/CurrentStockTabS0104.vue')
      },
      {
        path: 'palletInfo',
        name: '파레트 정보',
        component: () => import('../views/Pallet/PalletInfo.vue')
      },
      {
        path: 'badReceipt',
        name: '생산중 불량 등록',
        component: () => import('../views/BadManagement/BadReceipt.vue')
      },
      {
        path: 'badReceiptReg',
        name: '생산등록완료 불량 등록',
        component: () => import('../views/BadManagement/BadReceiptRegEnd.vue')
      },
      {
        path: 'badReceiptPurchase',
        name: '구매품 불량 등록',
        component: () => import('../views/BadManagement/BadReceiptPurchase.vue')
      },
      {
        path: 'badReceiptPurchaseS0409',
        name: '외주직납품 불량 등록',
        component: () => import('../views/BadManagement/BadReceiptRegEndS0409.vue')
      },
      {
        path: 'badReceiptList',
        name: '불량품 관리내역',
        component: () => import('../views/BadManagement/BadReceiptList.vue')
      },
      // 생산 실적관리
      {
        path: 'weekPlan',
        name: '주간생산계획',
        component: () => import('../views/Manufacturing/WeekPlanTab.vue')
      },
      {
        path: 'workOrder',
        name: '일 작업계획서',
        component: () => import('../views/Manufacturing/WorkOrder.vue')
      },
      {
        path: 'workOutput',
        name: '작업지시 리스트',
        component: () => import('../views/Manufacturing/WorkOutput.vue')
      },
      {
        path: 'regMachineFault',
        name: '설비고장신고',
        component: () => import('../views/Manufacturing/MachineFaultReg.vue')
      },
      {
        path: 'inventoryCount',
        name: '사급품 실사',
        component: () => import('../views/InventoryCount.vue')
      },
      {
        path: 'wipCount',
        name: '구매품 재공 실사',
        component: () => import('../views/InventoryWip.vue')
      },
      {
        path: 'productAndWipCount',
        name: '완/반제품 재공 실사',
        component: () => import('../views/ProductAndWipCount.vue')
      },
      {
        path: 'productQrcode',
        name: '부품식별표 프린트',
        component: () => import('../views/GoodsQrCode/QrCodeTab.vue')
      },
      {
        path: 'qrCodeScan',
        name: 'QR코드 스캔',
        component: () => import('../views/GoodsQrCode/QrcodeReader.vue')
      },
      {
        path: 'mypage',
        name: '비번변경',
        component: MyPage
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
